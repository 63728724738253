import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import {
  Container, Row, Col
} from 'react-bootstrap'
import Footer from "../components/footer";
import Header from "../components/header";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      title
      description
      content
      order
      gallery {
        caption
        image {
          publicURL
          childImageSharp {
            fluid (fit: COVER, sizes: "576px, 992px, 1680px", srcSetBreakpoints: [576, 992, 1680]) {
              srcSet
              srcSetWebp
            }
          }
        }
      }
      image {
        childImageSharp {
          fluid (fit: COVER, sizes: "576px, 992px, 1680px", srcSetBreakpoints: [576, 992, 1680]) {
            srcSet
            srcSetWebp
            src
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {

  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: {
      publicURL: article.image.childImageSharp.fluid.src
    },
    article: true,
  };

  const images = article.gallery
    .filter(item => (item.image || {}).publicURL)
    .map(item => ({
      thumb: item.image.childImageSharp.fluid,
      large: { src: item.image.publicURL },
      caption: item.caption
    }))

  const [imageIndex, setimageIndex] = useState(0)
  const [opened, setOpened] = useState()

  const onImageClick = useCallback(idx => {
    setimageIndex(idx)
    setOpened(true)
  }, [setOpened])

  return (
    <Layout seo={seo}>
      <Header
        image={article.image.childImageSharp.fluid}
        title={article.title}
        description={article.description}
      />

      <section className="mr-page-section">
        <Container>
          <Row>
            <Col>
              <Markdown source={article.content} escapeHtml={false} />
            </Col>
          </Row>
        </Container>
      </section>

      {
        images.length ? (
          <>
            <section className="mr-section-portfolio">
              <div className="mr-portfolio-grid">
                {
                  images.map((img, idx) => (
                    <a
                      key={idx}
                      href="#"
                      className="portfolio-item mr-item-image"
                      onClick={e => {
                        e.preventDefault()
                        onImageClick(idx)
                      }}
                    >
                      <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <GatsbyImage
                          fluid={img.thumb}
                          title={img.caption}
                          alt={img.caption}
                        />
                      </div>
                      {
                        img.caption ? (
                          <div className="portfolio-info">
                            <h3>{img.caption}</h3>
                          </div>
                        ) : null
                      }
                    </a>
                  ))
                }
              </div>
            </section>
            {
              opened ? (
                <Lightbox
                  clickOutsideToClose
                  mainSrcThumbnail={images[imageIndex].thumb.src}
                  mainSrc={images[imageIndex].large.src}
                  nextSrcThumbnail={images[(imageIndex + 1) % images.length].thumb.src}
                  nextSrc={images[(imageIndex + 1) % images.length].large.src}
                  prevSrcThumbnail={images[(imageIndex + images.length - 1) % images.length].thumb.src}
                  prevSrc={images[(imageIndex + images.length - 1) % images.length].large.src}
                  imageCaption={images[imageIndex].caption}
                  onCloseRequest={() => setOpened(false)}
                  onMovePrevRequest={() => (
                    setimageIndex((imageIndex + images.length - 1) % images.length)
                  )}
                  onMoveNextRequest={() => (
                    setimageIndex((imageIndex + 1) % images.length)
                  )}
                />
              ) : null
            }
          </>
        ) : null
      }

      <Footer />
    </Layout>
  );
};

export default Article;
